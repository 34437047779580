import React, {createContext, useContext, useEffect, useState} from 'react';
import {ActionPlanDTO, ProfileDTO} from "../../../../../store/manager/types";
import {updateProfile} from "../../../../../store/profiles/actions";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {LoadingImage, StyledLoadingScreen} from "../../../../loading/LoadingImage";


export interface Settings {

    // system level
    pauseTimerDuringBreaks: boolean
    enablePlayerTracking: boolean
    combatOnlyTrainDefenseUsingRange: boolean


    // breaking
    startPlayTime: number
    endPlayTime: number
    startBreakTime: number
    endBreakTime: number

    // quests
    doricsQuest: boolean
    blackKnightFortressQuest: boolean
    cooksAssistantQuest: boolean
    goblinDiplomacyQuest: boolean
    restlessGhostQuest: boolean
    sheepShearerQuest: boolean
    impCatcherQuest: boolean
    ernestTheChickenQuest: boolean
    theKnightsSwordQuest: boolean
    vampyreSlayerQuest: boolean
    witchPotionQuest: boolean
    runeMysteriesQuest: boolean
    romeoAndJulietQuest: boolean

    // levels
    fishingLevel: number
    firemakingLevel: number
    cookingLevel: number
    craftingLevel: number
    woodcuttingLevel: number
    miningLevel: number
    strengthLevel: number
    attackLevel: number
    defenceLevel: number
    rangeLevel: number
    magicLevel: number
    smithingLevel: number


    // crafting
    craftingLeatherRange: string
    craftingGemRange: string
    craftingTimeRange: string


    // mining
    useQuestToSkipEarlyMiningLevels: boolean
    miningBuyAllPickaxesAtOneTime: boolean
    miningTimeRange: string

    // cooking
    cookingAnchoviesRange: string
    cookingTroutRange: string
    cookingTunaRange: string
    cookingSellCookedFish: boolean
    cookingTimeRange: string

    // fishing
    fishingFeatherRange: string
    fishingTimeRange: string

    // firemaking
    firemakingNormalLogRange: string
    firemakingOakLogRange: string
    firemakingWillowLogRange: string
    firemakingTimeRange: string

    // combat
    meleeTimeRange: string
    meleeBalanceLevels: boolean
    meleeEnableBuryBones: boolean
    meleeBuyAllItemAtOneTime: boolean
    meleeFoodRange: string

    // woodcutting
    woodcuttingTimeRange: string
    woodcuttingBuyAllAxesAtOneTime: boolean

    // range
    rangeTimeRange: string
    rangeFoodRange: string
    rangeIronArrowRange: string
    rangeSteelArrowRange: string
    rangeMithrilArrowRange: string
    rangeBuyAllItemAtOneTime: boolean
    rangeEnableDefenseTraining: boolean
    rangeEnableBuryBones: boolean

    // magic
    magicTimeRange: string

    //smithing
    smithingTimeRange: string
    useQuestToSkipEarlySmithingLevels: boolean
    smithingBronzeBarRange: string
    smithingIronBarRange: string
    smithingSteelBarRange: string
    smithingMithrilBarRange: string


}

const defaultSettings: Settings = {

    // System
    pauseTimerDuringBreaks: false,
    enablePlayerTracking: true,
    combatOnlyTrainDefenseUsingRange: false,

    // breaking
    startPlayTime: 600,
    endPlayTime: 1200,
    startBreakTime: 300,
    endBreakTime: 600,

    // quests
    doricsQuest: false,
    blackKnightFortressQuest: false,
    cooksAssistantQuest: false,
    goblinDiplomacyQuest: false,
    restlessGhostQuest: false,
    sheepShearerQuest: false,
    impCatcherQuest: false,
    ernestTheChickenQuest: false,
    theKnightsSwordQuest: false,
    vampyreSlayerQuest: false,
    witchPotionQuest: false,
    runeMysteriesQuest: false,
    romeoAndJulietQuest: false,


    // level
    fishingLevel: 0,
    firemakingLevel: 0,
    cookingLevel: 0,
    craftingLevel: 0,
    woodcuttingLevel: 0,
    miningLevel: 0,
    strengthLevel: 0,
    attackLevel: 0,
    defenceLevel: 0,
    rangeLevel: 0,
    magicLevel: 0,
    smithingLevel: 0,

    // crafting
    craftingLeatherRange: "300-400",
    craftingGemRange: "300-400",
    craftingTimeRange: "3600-7200",


    // mining
    useQuestToSkipEarlyMiningLevels: false,
    miningBuyAllPickaxesAtOneTime: true,
    miningTimeRange: "3600-7200",

    // cooking
    cookingAnchoviesRange: "150-300",
    cookingTroutRange: "300-400",
    cookingTunaRange: "700-800",
    cookingSellCookedFish: true,
    cookingTimeRange: "3600-7200",

    // fishing
    fishingFeatherRange: "2000-4000",
    fishingTimeRange: "3600-7200",

    // firemaking
    firemakingNormalLogRange: "500-1000",
    firemakingOakLogRange: "500-1000",
    firemakingWillowLogRange: "500-1000",
    firemakingTimeRange: "3600-7200",

    // melee
    // meleeBuyAllItemAtOneTime: true,
    meleeTimeRange: "3600-7200",
    meleeBalanceLevels: true,
    meleeEnableBuryBones: false,
    meleeBuyAllItemAtOneTime: true,
    meleeFoodRange: "100-200",


    // woodcutting
    woodcuttingTimeRange: "3600-7200",
    woodcuttingBuyAllAxesAtOneTime: true,


    // range
    rangeTimeRange: "3600-7200",
    rangeFoodRange: "50-100",
    rangeIronArrowRange: "500-1000",
    rangeSteelArrowRange: "1000-2000",
    rangeMithrilArrowRange: "1000-2000",
    rangeBuyAllItemAtOneTime: true,
    rangeEnableDefenseTraining: false,
    rangeEnableBuryBones: false,

    // magic
    magicTimeRange: "3600-7200",

    // smithing
    smithingTimeRange: "3600-7200",
    useQuestToSkipEarlySmithingLevels: false,
    smithingBronzeBarRange: "150-300",
    smithingIronBarRange: "150-300",
    smithingSteelBarRange: "150-300",
    smithingMithrilBarRange: "150-300",


}


// Define the shape of the context data
interface SettingContextState {
    settings: Settings;
    saveSettings: () => void;
    updateSetting: (key: string, value: any) => void;
}

// Update the SettingContext
export const SettingContext = createContext<SettingContextState>({
    settings: defaultSettings,
    saveSettings: () => {
    }, // Placeholder function
    updateSetting: () => {
    }, // Placeholder function
});


interface SettingProviderProps {
    children: React.ReactNode;
    profile?: ProfileDTO
}

export const SettingContextProvider: React.FC<SettingProviderProps> = ({children, profile}) => {

    // i want to make an axios call which getting the setting cfrom the DB and merges the defaultSettings with the setting that came back from teh db

    const dispatch = useDispatch();
    const [settings, setSettings] = useState<Settings>(defaultSettings);
    const [isSettingsLoaded, setIsSettingsLoaded] = useState(false); // New state to track if settings are loaded

    // Effect to merge profile settings with default settings
    useEffect(() => {
        if (profile && profile.text) {
            try {
                const profileSettings = JSON.parse(profile.text);
                setSettings(prevSettings => ({...defaultSettings, ...profileSettings}));
                setIsSettingsLoaded(true); // Set to true once the settings are loaded
            } catch (error) {
                console.error('Error parsing profile settings:', error);
            }
        } else {
            setIsSettingsLoaded(true); // Set to true if there's no profile to merge
        }
    }, [profile]); // Dependency array includes 'profile' to run effect when it changes


    const saveSettings = () => {
        if (!profile) {
            toast.error('Error saving data', {
                position: toast.POSITION.TOP_CENTER
            });
            return
        }
        console.log(JSON.stringify(settings))
        dispatch(updateProfile({
            "profileKey": profile?.profileKey ?? "",
            "name": profile?.name ?? "",
            "text": JSON.stringify(settings)
        }));
        toast.success('Successful save data', {
            position: toast.POSITION.TOP_CENTER
        });
    }

    // Updated updateSetting function
    const updateSetting = (key: string, value: any) => {
        if (key in settings) {
            setSettings(prevSettings => ({...prevSettings, [key]: value}));
        } else {
            console.warn(`Invalid setting key: ${key}`);
        }
    };

    if (!isSettingsLoaded) {
        return (
            <StyledLoadingScreen>
                <LoadingImage/>
            </StyledLoadingScreen>)
    }

    return (
        <SettingContext.Provider value={{settings, saveSettings, updateSetting}}>
            {children}
        </SettingContext.Provider>
    );
};